<template>
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="修改店铺信息"
    ></el-page-header>
    <div class="select-container">
      <el-form
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="店铺标识ID">
          <el-input
            style="width: 30%"
            v-model="info.storeFlag"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="店铺名称">
          <el-input
            style="width: 30%"
            v-model="info.title"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业额">
          <el-input style="width: 30%" v-model="info.turnover"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input style="width: 30%" v-model="info._site"></el-input>
        </el-form-item>
        <el-form-item label="营业时间">
          <el-time-select
            placeholder="起始时间"
            v-model="info._startTime"
            :picker-options="{
              start: '06:30',
              step: '00:15',
              end: '23:30',
            }"
          >
          </el-time-select>
          <span> - </span>
          <el-time-select
            placeholder="结束时间"
            v-model="info._endTime"
            :picker-options="{
              start: '06:30',
              step: '00:15',
              end: '23:30',
              minTime: info.startTime,
            }"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio v-model="info.state" label="1">正常</el-radio>
          <el-radio v-model="info.state" label="2">停业/临时冻结</el-radio>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input style="width: 30%" v-model="info.phonenum"></el-input>
        </el-form-item>
        <!-- <el-form-item label="最后修改时间">
          <el-input style="width: 30%" v-model="info.fix_time" disabled></el-input>
        </el-form-item> -->
        <div class="line"></div>
        <h3>店铺绑定账号</h3>
        <el-table
          :data="tableData"
          style="width: 100%"
          height="200"
          :cell-style="csllStyle"
        >
          <el-table-column fixed prop="cardNo" label="账号" width="150">
          </el-table-column>
          <el-table-column prop="password" label="密码" width="120">
          </el-table-column>
          <el-table-column prop="username" label="用户名" width="160">
          </el-table-column>
          <el-table-column prop="verify" label="权限" width="120">
          </el-table-column>
          <el-table-column prop="adminState" label="账号状态" width="300">
          </el-table-column>
        </el-table>
        <el-form-item class="button-bottom">
          <el-button type="primary" @click="onSubmit">保存修改</el-button>
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import moment from "moment";
import secret from "@/utils/secret.js";
export default {
  inject: ["reload"],
  data() {
    let self = this;
    let NoEvent = function () {
      self.verify();
    };
    return {
      info: "",
      tableData: "",
    };
  },
  created() {
    this.info = JSON.parse(secret.Decrypt(this.$route.params.id));
    this.info.fix_time = moment().format("YYYY-MM-DD HH:mm:ss");
  },
  mounted() {
    const userKey = this.info.storeFlag;
    let userInfo = true;
    axios
      .post(this.GLOBAL.api + "/store", { userKey, userInfo })
      .then((res) => {
        this.tableData = res.data.val;
      });
  },
  watch: {
    'info.state':{
      deep: true,
      handler:function(res) {
        if (res === '2') {
          this.tableData.forEach((ele,i) => {
            this.tableData[i].adminState = '2'
          });
        } else {
          this.tableData.forEach((ele,i) => {
            this.tableData[i].adminState = '1'
          });
        }
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push("/store/list");
    },
    onSubmit() {
      const update = true;
      const storeInfo = JSON.stringify(this.info);
      const storeUser = JSON.stringify(this.tableData);
      axios.post(this.GLOBAL.api + '/store', {update, storeInfo, storeUser}).then(res => {
        if(res.status === 200 && res.data.result_1) {
          this.$message({
            type: 'success',
            message: '修改成功！'
          })
        } else {
          this.$message({
            type: 'error',
            message: '修改失败请重试！'
          })
        }
      })
    },
    onCancel() {
      this.$confirm("您还未保存修改, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("/store/list");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    csllStyle(row) {
      // // 监听tabel数据进行操作，如颜色警告
      if (row.row.verify === "1") {
        return (row.row.verify = "超级管理员");
        // row.column.label = '正常'
      } else if (row.row.verify === "2") {
        return (row.row.verify = "普通管理员");
        // row.column.label = '正常'
      }
      if (row.row.adminState === "1") {
        return (row.row.adminState = "正常");
        // row.column.label = '正常'
      } else if (row.row.adminState === "2") {
        return (row.row.adminState = "冻结/临时停用");
        
        // row.column.label = '正常'
      }
    },
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 1% 3%;
  position: relative;
}
.box-card {
  width: 100%;
  height: 100%;
}
.iconStyle {
  color: green;
  font-weight: bold;
  position: absolute;
  top: 2%;
  left: 40%;
}
.line {
  border: 1px dashed;
  margin-bottom: 10px;
}
.button-bottom {
  margin-top: 20px;
}
</style>